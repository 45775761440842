import React from 'react';
import { ListItemText, ListItem, ListItemIcon, List } from '@material-ui/core';
import { GoPrimitiveDot } from 'react-icons/go';
import styles from './TipList.module.css';

type TipListProps = { tiplist: Array<string> };

const TipList = ({ tiplist }: TipListProps) => {
  return (
    <List dense disablePadding>
      {tiplist.map((tip) => (
        <div>
          <ListItem disableGutters>
            <ListItemIcon classes={{ root: styles.root }}>
              <GoPrimitiveDot />
            </ListItemIcon>
            <ListItemText primary={tip} />
          </ListItem>
        </div>
      ))}
    </List>
  );
};

export default TipList;
